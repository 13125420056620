<template>
  <svg
    width="225"
    height="331"
    viewBox="0 0 225 331"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.2195 330.797H208.527C204.315 323.716 199.297 317.084 193.382 310.99C171.696 289.301 144.633 278.457 112.104 278.457C79.5751 278.457 52.333 289.301 31.2742 310.99C25.4494 316.816 20.5208 323.537 16.309 330.797H16.2195Z"
      fill="#ED166E"
    />
    <path
      d="M193.383 258.651C171.697 236.962 144.634 226.118 112.105 226.118C79.5759 226.118 52.3338 236.962 31.275 258.651C25.4502 264.476 20.5216 271.198 16.3098 278.457H208.617C204.405 271.377 199.387 264.745 193.473 258.651H193.383Z"
      fill="#ED166E"
    />
    <path
      d="M112.373 0C78.4105 0 51.2581 10.217 30.737 30.7405C10.2158 50.9056 0 76.7168 0 108.264C0 139.811 10.2158 165.712 30.737 186.236C51.1685 206.401 78.4105 216.528 112.373 216.528C146.336 216.528 172.772 206.401 193.562 186.236C214.352 165.443 224.747 139.453 224.747 108.264C224.747 77.0753 214.352 50.9056 193.562 30.7405C173.041 10.3066 146.067 0 112.373 0ZM152.968 150.835C142.393 161.858 128.862 167.325 112.373 167.325C95.8848 167.325 82.8015 161.769 72.2273 150.566C61.6531 139.453 56.366 125.292 56.366 108.174C56.366 91.0564 61.7426 76.9857 72.4064 65.9621C83.0703 54.9386 96.4225 49.4716 112.284 49.4716C128.145 49.4716 141.945 54.9386 152.609 65.9621C163.273 76.9857 168.65 91.0564 168.65 108.174C168.65 125.292 163.363 139.811 152.878 150.835H152.968Z"
      fill="white"
    />
  </svg>
</template>

<script setup lang="ts">

</script>

<style scoped>

</style>
